import {ClassGradingTable, IStudentEntry} from "common/components/ClassGradingTable";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {getLessonsForCurrentCourse} from "common/data/getLessonsForCurrentCourse";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    Button,
    IconButton,
    Toolbar,
    Tooltip,
    Typography
} from "@mui/material";
import {SubmitLessonGradesDialog} from "dialogs/SubmitLessonGradesDialog";
import {ConfigureLessonDialog} from "dialogs/ConfigureLessonDialog";
import {ICourseTaskWithExamInfo} from "common/types/VutApi";
import {useQuery} from "@tanstack/react-query";
import {StudentGradingState, TaskGrading} from "../common/helpers/taskGrading";
import {useLocalStorageState} from "common/hooks/useLocalStorageState";
import {useVutApiSession} from "contexts/VutApiSession";
import {VutApi} from "common/helpers/VutApi";
import {FitCvtApi} from "common/helpers/FitCvtApi";
import {getRoomsForClassLesson} from "../common/data/getRoomsForClassLesson";
import {getCourseTaskStudents} from "../common/data/getCourseTaskStudents";
import {getCourseClassStudents} from "../common/data/getCourseClassStudents";
import {flatAwait} from "../common/helpers/promises";
import {LessonLabSeatingOverview} from "../common/components/LessonLabSeatingOverview";
import {Add, Backspace, ChevronLeft, ChevronRight, ExpandMore, Remove} from "@mui/icons-material";
import {CvtActivitySummary} from "libs/fit-cvt-api";
import {PageContentContainer} from "common/components/PageContentContainer";
import {useDocumentTitle} from "../common/hooks/useDocumentTitle";
import {StudentSeating} from "../common/components/StudentSeating";
import {useForceLoginOn401} from "../common/hooks/useForceLoginOn401";
import ErrorBoundary from "common/components/ErrorBoundary";


export default function LessonOverviewPage() {
    useDocumentTitle("FIT LOKI - Lesson overview");
    const vutSession = useVutApiSession();
    const vutApi = new VutApi(vutSession)
    const rozvrhApi = vutApi.rozvrh()
    const zadaniApi = vutApi.zadani()
    const fitCvtApi = new FitCvtApi()
    const laboratoryLogsApi = fitCvtApi.labLogs();

    const defaultViewId = "table-view"

    const {aktualniPredmetId, vyucovaniId, vyucBlokDenId} = useParams();
    const [studentiVyucovani, setStudentiVyucovani] = useState<IStudentEntry[]>([]);
    const [activeView, setActiveView] = useState<string | undefined>(defaultViewId);
    const [task, setTask] = useLocalStorageState<ICourseTaskWithExamInfo | undefined>(`/course/${aktualniPredmetId}/class/${vyucovaniId}/corresponding-task`, undefined);
    const [lessonNumber, setLessonNumber] = useLocalStorageState<number | undefined>(`/course/${aktualniPredmetId}/class/${vyucovaniId}/day/${vyucBlokDenId}/lesson-number`, undefined);
    const [localGrading, setLocalGrading] = useLocalStorageState<Record<number, number | null>>(`/course/${aktualniPredmetId}/class/${vyucovaniId}/day/${vyucBlokDenId}/grading`, {});
    const [error, setError] = useState<Error | undefined>();
    const [submitGradesDialogIsOpen, setSubmitGradesDialogIsOpen] = useState(false);
    const [configureLessonDialogIsOpen, setConfigureLessonDialogIsOpen] = useState(false);

    const grading = useMemo(() => new TaskGrading(task, lessonNumber), [task, lessonNumber]);
    useForceLoginOn401(error);

    const currentCourseId = parseInt(aktualniPredmetId!);
    const classId = parseInt(vyucovaniId!);
    const lessonId = parseInt(vyucBlokDenId!);
    const zadaniId = task?.zadani_id!;

    const {
        data: lessonContext,
    } = useQuery([currentCourseId, "vyucovani", lessonId], async () => {
        const vyucovani = await rozvrhApi.getAktualniPredmetVyucovaniV3(currentCourseId.toString());
        const blokDny = vyucovani.data.data?.vyucovani
            ?.flatMap(vyucovani => vyucovani.bloky)
            ?.filter(blok => blok?.dny?.some(den => den.vbd_vyuc_blok_den_id === lessonId))
            ?.flatMap(blok => blok?.dny);

        // get index of current lesson by lessonId
        const currentLessonIndex = blokDny?.findIndex(den => den?.vbd_vyuc_blok_den_id === lessonId);
        if (currentLessonIndex === undefined || currentLessonIndex === -1) {
            return {previous: undefined, current: undefined, next: undefined};
        }

        return {
            previous: blokDny?.[currentLessonIndex - 1],
            current: blokDny?.[currentLessonIndex],
            next: blokDny?.[currentLessonIndex + 1]
        };
    }, {enabled: Boolean(currentCourseId) && Boolean(lessonId), onError: setError})

    const {
        data: taskStudents,
        // isLoading: taskStudentsAreLoading,
        isError: taskStudentsErrored,
        refetch: taskStudentsRefetch,
    } = useQuery([currentCourseId, "zadani", zadaniId, "studenti"], async () => {
        return await getCourseTaskStudents(zadaniApi, currentCourseId, zadaniId)
    }, {enabled: Boolean(aktualniPredmetId) && Boolean(zadaniId), onError: setError})

    const {
        data: lessonStudents,
        isLoading: lessonStudentsAreLoading,
        isError: lessonStudentsErrored,
        refetch: lessonStudentsRefetch
    } = useQuery([currentCourseId, "vyucovani", classId, "studenti"], async () => {
        return await getCourseClassStudents(rozvrhApi, currentCourseId, classId)
    }, {onError: setError,})

    const {
        data: lesson,
        isLoading: lessonIsLoading,
    } = useQuery([currentCourseId, "vyuka", lessonId], async () => {
        return await getLessonsForCurrentCourse(rozvrhApi, currentCourseId)
            .then(vyuka => vyuka.find(v => v.id === lessonId))
    }, {onError: setError})

    const {
        data: lessonRooms,
        isLoading: lessonRoomsAreLoading,
    } = useQuery([currentCourseId, "vyuka", lessonId, "mistnosti"], async () => {
        return await getRoomsForClassLesson(rozvrhApi, lessonId)
            .then(rooms => rooms.map(room => room.mistnost_label!.split("/")[1]))
    }, {onError: setError})

    const {
        data: laboratoryLogins = [],
        isLoading: laboratoryLoginsAreLoading,
    } = useQuery(["lesson", lessonId, "rooms", lessonRooms, "from", lesson?.date_from, "to", lesson?.date_to, "logins"], async () => {
        const dateFrom = lesson!.date_from.toISOString();
        const dateTo = lesson!.date_to.toISOString();

        const roomsLoginPromise = lessonRooms?.map(
            mistnost => laboratoryLogsApi.getLoginsPresentInLab(mistnost, dateFrom, dateTo)
                .then(response => response.data)) ?? []

        return await flatAwait(roomsLoginPromise)

    }, {
        enabled: Boolean(lessonRooms) && Boolean(lesson?.date_from) && Boolean(lesson?.date_to),
        onError: setError,
    });

    const decorateStudentEntry = useCallback((student: IStudentEntry): IStudentEntry => {
        const studentTaskEntry = taskStudents?.find(taskStudent => taskStudent.per_id === student.per_id);
        const isValid = Boolean(studentTaskEntry);
        const studentLogin = student.login?.toString() ?? "unknown";
        const isPresent = Boolean(laboratoryLogins.find(login => login === studentLogin || login === (`x${student.per_id}`)));
        const lessonCurrent = grading.getCurrentLessonGrade(studentTaskEntry);
        const totalCurrent = grading.getCurrentStudentGrading(studentTaskEntry);
        const lessonNew = localGrading?.[student.per_id!] ?? null
        const totalNew = grading.getNewStudentGrading(studentTaskEntry, lessonNew);

        let gradingState = StudentGradingState.NotGraded;
        if (!isValid) {
            gradingState = StudentGradingState.Impossible;
        } else if (lessonCurrent !== null && lessonNew == null) {
            gradingState = StudentGradingState.Impossible;
        } else if (totalCurrent !== totalNew || lessonCurrent !== lessonNew) {
            gradingState = StudentGradingState.Possible;
        }

        return {
            ...student,
            ...studentTaskEntry,
            isValid: isValid,
            isPresent: isPresent,
            pointsLessonCurrent: lessonCurrent,
            pointsLessonNew: lessonNew,
            pointsTotalCurrent: totalCurrent,
            pointsTotalNew: totalNew,
            gradingState: gradingState,
        }
    }, [localGrading, grading, laboratoryLogins, taskStudents]);

    useEffect(() => {
        // @ts-ignore
        const decoratedResult = lessonStudents?.map(decorateStudentEntry);
        setStudentiVyucovani(decoratedResult ?? []);
    }, [localGrading, lesson, lessonStudents, lessonRooms, taskStudents, laboratoryLogins, task, decorateStudentEntry]);

    useEffect(() => {
        if (!submitGradesDialogIsOpen && lessonStudents) {
            lessonStudentsRefetch();
        }
    }, [lessonStudents, lessonStudentsRefetch, submitGradesDialogIsOpen]);

    useEffect(() => {
        console.log("processing lessonNumber effect")
        if (!task?.pocet_otazek) {
            setLessonNumber(undefined);
            console.log("setting undef")
        } else if (typeof lesson?.indexWithoutCancelled === "number" && lessonNumber === undefined) {
            setLessonNumber(lesson.indexWithoutCancelled + 1);
            console.log("setting", lesson.indexWithoutCancelled + 1);
        }
    }, [task, lesson, setLessonNumber, lessonNumber]);

    const handleImportGrading = useCallback(() => {
        const reduce = taskStudents?.map(s => ({[s.per_id!]: grading.getCurrentLessonGrade(s)}))
            .reduce((prev, curr) => ({...prev, ...curr}), {});
        setLocalGrading(reduce ?? []);
    }, [setLocalGrading, taskStudents, grading]);

    const createHandleActiveViewChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setActiveView(isExpanded ? panel : undefined);
        };

    const createHandleIncrementGrading = useCallback((per_id: number | undefined) => () => {
        if (per_id) {
            const currentGrade = localGrading[per_id] ?? 0;
            setLocalGrading({...localGrading, [per_id]: currentGrade + 1});
        }
    }, [localGrading, setLocalGrading]);

    const createHandleDecrementGrading = useCallback((per_id: number | undefined) => () => {
        if (per_id) {
            const currentGrade = localGrading[per_id] ?? 0;
            setLocalGrading({...localGrading, [per_id]: Math.max(currentGrade - 1, 0)});
        }
    }, [localGrading, setLocalGrading]);

    const createHandleRemoveGrading = useCallback((per_id: number | undefined) => () => {
        if (per_id) {
            setLocalGrading({...localGrading, [per_id]: null});
        }
    }, [localGrading, setLocalGrading]);

    const createRenderSeatingStudentActions = useCallback(() => {
        return (activity: CvtActivitySummary | undefined) => {
            const student = activity ? lessonStudents?.find(s => s.login === activity?.login || (`x${s.per_id}`) === activity?.login) : null;
            const handleIncrementGrading = createHandleIncrementGrading(student?.per_id);
            const handleDecrementGrading = createHandleDecrementGrading(student?.per_id);
            const handleRemoveGrading = createHandleRemoveGrading(student?.per_id);

            return <Box sx={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                <Box>
                    <Tooltip title={"Add 1 point"}>
                        <IconButton onClick={handleIncrementGrading} disabled={!student}>
                            <Add sx={{fontSize: 16, color: "success.main"}}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"Remove 1 point"}>
                        <IconButton onClick={handleDecrementGrading} disabled={!student}>
                            <Remove sx={{fontSize: 16, color: "error.main"}}/>
                        </IconButton>
                    </Tooltip>
                </Box>
                <Tooltip title={"Clear points"}>
                    <IconButton onClick={handleRemoveGrading} disabled={!student}>
                        <Backspace sx={{fontSize: 16}}/>
                    </IconButton>
                </Tooltip>
            </Box>
        }
    }, [createHandleDecrementGrading, createHandleIncrementGrading, createHandleRemoveGrading, lessonStudents]);

    function renderSeatingStudentContent(activity: CvtActivitySummary | undefined) {
        const student = activity ? lessonStudents?.find(s => s.login === activity?.login || (`x${s.per_id}`) === activity?.login) : null;
        const grading = localGrading[student?.per_id ?? -1] ?? null;
        return <StudentSeating activity={activity} student={student} grading={grading}/>
    }

    const navigate = useNavigate();
    return (
        <div>
            <PageContentContainer>
                {!lessonStudentsAreLoading && lessonStudents?.length === 0 &&
                    <Alert severity={"warning"} sx={{mb: "1em"}}>
                        No students were loaded for this lesson.
                        This might be due to insufficient permissions.
                        Is this your own lesson?
                    </Alert>}

                <Box sx={{boxShadow: 1}}>
                    <Toolbar variant={"dense"} sx={{justifyContent: "space-between"}}>
                        <Tooltip title={`Lesson on ${lessonContext?.previous?.vbd_datum?.split("T")[0]}`}>
                            <Button startIcon={<ChevronLeft/>} onClick={() => {
                                navigate(`/course/${currentCourseId}/class/${classId}/lesson/${lessonContext?.previous?.vbd_vyuc_blok_den_id}`);
                            }} disabled={!Boolean(lessonContext?.previous)}>
                                Previous lesson
                            </Button>
                        </Tooltip>
                        <Typography variant={"overline"}>
                            {lessonContext?.current && `Current lesson ${lessonContext?.current?.vbd_datum?.split("T")[0]}` + (lessonContext?.current?.vbd_zruseno ? " (cancelled)" : lessonNumber ? ` (part ${lessonNumber})` : "")}
                        </Typography>
                        <Tooltip title={`Lesson on ${lessonContext?.next?.vbd_datum?.split("T")[0]}`}>
                            <Button endIcon={<ChevronRight/>} onClick={() => {
                                navigate(`/course/${currentCourseId}/class/${classId}/lesson/${lessonContext?.next?.vbd_vyuc_blok_den_id}`);
                            }} disabled={!Boolean(lessonContext?.next)}>
                                Next lesson
                            </Button>
                        </Tooltip>
                    </Toolbar>
                </Box>
                <ErrorBoundary error={error}>
                    {lessonRooms && lessonRooms.map(room => {
                        const viewId = `${room}-seating-view`;
                        return (
                            <Accordion key={viewId} expanded={activeView === viewId}
                                       onChange={createHandleActiveViewChange(viewId)}>
                                <AccordionSummary expandIcon={<ExpandMore/>}>
                                    <Typography sx={{width: "20%", flexShrink: 0}}>
                                        {room} Seating
                                    </Typography>
                                    <Typography sx={{color: "text.secondary"}}>
                                        View students' seating during the lesson
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {lesson &&
                                        <LessonLabSeatingOverview
                                            lessonId={lessonId}
                                            room={room} whenFrom={lesson!.date_from} whenTo={lesson!.date_to}
                                            renderContent={renderSeatingStudentContent}
                                            renderActions={createRenderSeatingStudentActions()}
                                        />}
                                </AccordionDetails>
                            </Accordion>
                        )
                    })}
                    <Box sx={{
                        mt: "1em",
                        flexGrow: 1,
                        minHeight: 500,
                        maxHeight: "100%",
                    }}>
                        <ClassGradingTable
                            classId={classId}
                            students={studentiVyucovani}
                            setPoints={(id, value) => setLocalGrading({...localGrading, [id]: value})}
                            toolbarProps={{
                                onImport: task ? handleImportGrading : undefined,
                                onConfigure: () => setConfigureLessonDialogIsOpen(true),
                                onSubmit: () => setSubmitGradesDialogIsOpen(true),
                            }}
                            isLoading={!taskStudentsErrored && !lessonStudentsErrored && (lessonIsLoading || lessonRoomsAreLoading || lessonStudentsAreLoading || laboratoryLoginsAreLoading)}
                        />
                    </Box>
                </ErrorBoundary>
            </PageContentContainer>
            {aktualniPredmetId && lesson && <SubmitLessonGradesDialog
                isOpen={submitGradesDialogIsOpen}
                setIsOpen={setSubmitGradesDialogIsOpen}
                students={studentiVyucovani}
                setStudents={setStudentiVyucovani}
                lessonNumber={lessonNumber}
                setLessonNumber={setLessonNumber}
                task={task}
                setTask={setTask}
                onSubmit={async () => {
                    await taskStudentsRefetch();
                }}
                lesson={lesson}
            />}
            {aktualniPredmetId && lesson && <ConfigureLessonDialog
                isOpen={configureLessonDialogIsOpen}
                setIsOpen={setConfigureLessonDialogIsOpen}
                task={task}
                setTask={setTask}
                lessonNumber={lessonNumber}
                setLessonNumber={setLessonNumber}
                lesson={lesson}
            />}
        </div>
    )
}
